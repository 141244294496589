import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Box, Button, List, ListItem, OutlinedInput, Theme, Typography, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';

import { CheckIcon } from 'assets';
import {
  PLAN_CODE_YEARLY,
  PLAN_CODE_MONTHLY,
  PLAN_CODE_TRIAL,
  SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE,
} from 'constants/payment';
import { HIPAA_BENEFITS } from 'constants/texts';
import { BackButton, DiscountInput, Info, SeatsCountSelector, Spinner } from 'components';
import { IApiRequests, useApiRequests } from 'hooks';
import {
  ApiPlan,
  ApiUser,
  ComputeSubscriptionProrationMode,
  ComputeSubscriptionProrationResponse,
  Coupon,
  CreateHostedPageResponse,
  GetPlanResponse,
  SubscriptionProrationApi,
} from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';

import styles from './SubscriptionDetailsPage.module.scss';

export function SubscriptionDetailsPage() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const { getPlan, createSubscriptionWithHostedPages, computeSubscriptionProration }: IApiRequests = useApiRequests();
  const navigate = useNavigate();

  const user: ApiUser = useAppSelector(selectUser)!;

  const [loading, setLoading] = useState<boolean>(true);
  const [plans, setPlans] = useState<ApiPlan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<string>(PLAN_CODE_MONTHLY);
  const [seatsCount, setSeatsCount] = useState<number>(1);
  const [subscriptionProration, setSubscriptionProration] = useState<SubscriptionProrationApi | null>(null);
  const [coupone, setCoupone] = useState<Coupon | null>(null);
  const [isBenefitsOpen, setIsBenefitsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isMobile || user.clinicUser?.subscriptionInfo?.planCode !== PLAN_CODE_TRIAL) {
      navigate('/');
      return;
    }

    Promise.all([getPlan(PLAN_CODE_MONTHLY), getPlan(PLAN_CODE_YEARLY)])
      .then((value: [GetPlanResponse, GetPlanResponse]) => {
        setPlans(value.map((planResponse: GetPlanResponse) => planResponse.plan));
      })
      .catch(() => {
        enqueueSnackbar('Plans fetching error', { variant: 'error' });
        navigate('/');
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);

    computeSubscriptionProration({
      plan: { code: selectedPlan, quantity: seatsCount },
      mode: ComputeSubscriptionProrationMode.ProrateNewSubscription,
      couponCode: coupone?.code,
    })
      .then((response: ComputeSubscriptionProrationResponse) => {
        setSubscriptionProration(response.subscriptionProration);
      })
      .catch((error) => {
        enqueueSnackbar('Calculation error: ' + error.message, { variant: 'error' });
        setSubscriptionProration(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [seatsCount, coupone, selectedPlan]);

  const pricePerOneSeatPerMonth: string = useMemo(() => {
    if (selectedPlan === PLAN_CODE_MONTHLY) {
      return plans[0]?.recurringPrice?.toFixed(2) ?? '';
    }

    return ((plans[1]?.recurringPrice ?? 0) / 12).toFixed(2);
  }, [selectedPlan, plans]);

  const calculationsSum: string = useMemo(() => {
    if (selectedPlan === PLAN_CODE_MONTHLY) {
      return ((plans[0]?.recurringPrice ?? 0) * seatsCount).toFixed(2);
    }

    return ((plans[1]?.recurringPrice ?? 0) * seatsCount).toFixed(2);
  }, [selectedPlan, plans, seatsCount]);

  const renewsDate: string = useMemo(() => {
    if (selectedPlan === PLAN_CODE_MONTHLY) {
      return 'Renews ' + moment(user.clinicUser.subscriptionInfo?.serverTime).add(1, 'M').format('MMMM D, YYYY');
    }

    return 'Renews ' + moment(user.clinicUser.subscriptionInfo?.serverTime).add(1, 'y').format('MMMM D, YYYY');
  }, [selectedPlan]);

  const handleMonthlyClick = (): void => {
    if (selectedPlan !== PLAN_CODE_MONTHLY) {
      setCoupone(null);
      setSelectedPlan(PLAN_CODE_MONTHLY);
    }
  };

  const handleYearlyClick = (): void => {
    if (selectedPlan !== PLAN_CODE_YEARLY) {
      setCoupone(null);
      setSelectedPlan(PLAN_CODE_YEARLY);
    }
  };

  const handleDecreaseSeat = (): void => {
    if (seatsCount > 1) {
      setSeatsCount(seatsCount - 1);
    }
  };

  const handleIncreaseSeat = (): void => {
    setSeatsCount(seatsCount + 1);
  };

  const handleApplyCoupon = (coupon: Coupon): void => {
    setCoupone(coupon);
  };

  const handleRemoveCoupon = (): void => {
    setCoupone(null);
  };

  const handlePayClick = (): void => {
    setLoading(true);

    createSubscriptionWithHostedPages({
      planCode: selectedPlan,
      quantity: seatsCount,
      couponCode: coupone?.code,
      redirectUrl: `${process.env.REACT_APP_PUBLIC_URI}/successPayment?type=${SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE}&redirectTo=settings&redirectToHash=subscriptions`,
    })
      .then((response: CreateHostedPageResponse) => {
        window.location.href = response.page.url;
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBackToSubscriptionsClick = (): void => {
    navigate('/settings#subscriptions');
  };

  const handleBenefitsClick = (): void => {
    setIsBenefitsOpen(true);
  };

  const handleBenefitsClose = (): void => {
    setIsBenefitsOpen(false);
  };

  return (
    <>
      {loading && <Spinner />}
      <div className={styles.page}>
        <BackButton text='Back to Subscription' onClick={handleBackToSubscriptionsClick} />
        {isBenefitsOpen && (
          <Box className={classnames(styles.container, styles.containerBenefits)}>
            <Typography variant='body4' component='span' color='primary' onClick={handleBenefitsClose}>
              Go back
            </Typography>

            <Typography variant='h2' component='h2'>
              Benefits of HIPAA<i>LINK</i>
            </Typography>

            <List>
              {HIPAA_BENEFITS.map((item: string, key: number) => (
                <ListItem key={key}>
                  <CheckIcon />
                  <Typography variant='body2' component='span'>
                    {item}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {!isBenefitsOpen && (
          <Box className={styles.container}>
            <Typography variant='body2' component='span' onClick={handleBenefitsClick}>
              Click here to view Subscription Benefits
            </Typography>

            <div className={styles.switcher}>
              <div
                onClick={handleMonthlyClick}
                className={classnames(styles.switcherItem, {
                  [styles.switcherItemActive]: selectedPlan === PLAN_CODE_MONTHLY,
                })}
              >
                <Typography variant='body5' component='span'>
                  Monthly
                </Typography>
              </div>
            </div>

            <div className={classnames(styles.row, styles.pricePerMonth)}>
              <Typography variant='h4' component='h4'>
                ${pricePerOneSeatPerMonth}/Month/Seat
              </Typography>

              <Info
                text='If your practice includes multiple providers, you can purchase and assign a seat to each practice provider. Use the staff page to assign seats
                 and manage your staff.'
                tooltipClassName={styles.tooltip}
              />

              <Typography variant='body2' component='span' color='#626363'>
                {selectedPlan === PLAN_CODE_MONTHLY ? 'Billed Monthly' : 'Billed Annualy'}
              </Typography>
            </div>

            <div className={styles.row}>
              <Typography variant='body2' component='span'>
                Total Seats
              </Typography>

              <SeatsCountSelector count={seatsCount} onDecrease={handleDecreaseSeat} onIncrease={handleIncreaseSeat} />
            </div>

            <div className={styles.row}>
              <Typography variant='body2' component='span'>
                Discount Code
              </Typography>

              {selectedPlan === PLAN_CODE_MONTHLY && (
                <DiscountInput onApply={handleApplyCoupon} onRemove={handleRemoveCoupon} plan={selectedPlan} />
              )}

              {selectedPlan === PLAN_CODE_YEARLY && (
                <DiscountInput onApply={handleApplyCoupon} onRemove={handleRemoveCoupon} plan={selectedPlan} />
              )}
            </div>

            <div className={styles.devider}></div>

            <div className={styles.row}>
              <div className={styles.calculationsRow}>
                <Typography variant='body2' component='span' color='#393534'>
                  Subtotal
                </Typography>

                <Typography variant='body2' component='span' color='#393534'>
                  {calculationsSum}
                </Typography>
              </div>

              <div className={styles.calculationsRow}>
                <Typography variant='body2' component='span' color='#393534'>
                  Discount
                </Typography>

                <Typography variant='body2' component='span' color='#393534'>
                  -${subscriptionProration?.initialDiscount.toFixed(2)}
                </Typography>
              </div>

              <div className={styles.calculationsRow}>
                <Typography variant='body4' component='span'>
                  Total
                </Typography>

                <Typography variant='body4' component='span'>
                  ${subscriptionProration?.initialChargeAmount.toFixed(2)}
                </Typography>
              </div>
            </div>

            <div className={styles.devider}></div>

            <div>
              <Typography variant='body2' component='span' color='#626363'>
                {renewsDate}
              </Typography>
            </div>

            <div className={styles.buttons}>
              <Button variant='contained' onClick={handlePayClick}>
                Pay ${subscriptionProration?.initialChargeAmount.toFixed(2)}
              </Button>
            </div>
          </Box>
        )}
      </div>
    </>
  );
}
