import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { Button, Theme, Typography, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';

import { CheckIcon } from 'assets';
import { DiscountInput, Info, SeatsCountSelector, Spinner } from 'components';
import { PLAN_CODE_MONTHLY, PLAN_CODE_YEARLY } from 'constants/payment';
import { HIPAA_BENEFITS } from 'constants/texts';
import { COUPON } from 'constants/index';
import {
  ApiPlan,
  ApiUser,
  ComputeSubscriptionProrationMode,
  ComputeSubscriptionProrationResponse,
  Coupon,
  GetPlanResponse,
  SubscriptionProrationApi,
} from 'types';
import { IApiRequests, useApiRequests, useLocalStorageState, useRegistration } from 'hooks';
import { IRegistrationContext } from 'context';
import { isCouponForMonthlyPlan } from 'utils';

import styles from './SelectPlanForm.module.scss';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';

export function SelectPlanForm() {
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { onUpdateSubscription, onLeaveOnTrial }: IRegistrationContext = useRegistration();
  const { getPlan, computeSubscriptionProration }: IApiRequests = useApiRequests();
  const user: ApiUser | null = useAppSelector(selectUser);

  const [couponInLocalstorage] = useLocalStorageState<Coupon | null>(COUPON, null);

  const [loading, setLoading] = useState<boolean>(true);
  const [isBenefits, setIsBenefits] = useState<boolean>(false);
  const [plans, setPlans] = useState<ApiPlan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<string>(PLAN_CODE_MONTHLY);
  const [seatsCount, setSeatsCount] = useState<number>(1);
  const [coupones, setCoupones] = useState<(Coupon | null)[]>([null, null]);
  const [subscriptionProration, setSubscriptionProration] = useState<SubscriptionProrationApi | null>(null);

  const selectedApplyedCoupon: Coupon | null = useMemo(() => {
    return selectedPlan === PLAN_CODE_MONTHLY ? coupones[0] : coupones[1];
  }, [selectedPlan, coupones]);

  const pricePerOneSeatPerMonth: string = useMemo(() => {
    if (selectedPlan === PLAN_CODE_MONTHLY) {
      return plans[0]?.recurringPrice?.toFixed(2) ?? '';
    }

    return ((plans[1]?.recurringPrice ?? 0) / 12).toFixed(2);
  }, [selectedPlan, plans]);

  const subtotal: number = useMemo(() => {
    if (selectedPlan === PLAN_CODE_MONTHLY) {
      return (plans[0]?.recurringPrice ?? 0) * seatsCount;
    }

    return (plans[1]?.recurringPrice ?? 0) * seatsCount;
  }, [selectedPlan, plans, seatsCount]);

  const subtotalString: string = useMemo(() => {
    return '$' + subtotal.toFixed(2);
  }, [subtotal]);

  useEffect(() => {
    Promise.all([getPlan(PLAN_CODE_MONTHLY), getPlan(PLAN_CODE_YEARLY)])
      .then((value: [GetPlanResponse, GetPlanResponse]) => {
        setPlans(value.map((planResponse: GetPlanResponse) => planResponse.plan));
        if (couponInLocalstorage) {
          if (!isCouponForMonthlyPlan(couponInLocalstorage)) {
            setSelectedPlan(PLAN_CODE_YEARLY);
          }
          setCoupones(
            isCouponForMonthlyPlan(couponInLocalstorage) ? [couponInLocalstorage, null] : [null, couponInLocalstorage],
          );
        }
      })
      .catch(() => {
        enqueueSnackbar('Plans fetching error', { variant: 'error' });
        navigate('/login');
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);

    computeSubscriptionProration({
      plan: { code: selectedPlan, quantity: seatsCount },
      mode: ComputeSubscriptionProrationMode.ProrateNewSubscription,
      couponCode: selectedApplyedCoupon?.code,
    })
      .then((response: ComputeSubscriptionProrationResponse) => {
        setSubscriptionProration(response.subscriptionProration);
      })
      .catch((error) => {
        enqueueSnackbar('Calculation error: ' + error.message, { variant: 'error' });
        setSubscriptionProration(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [seatsCount, selectedApplyedCoupon, selectedPlan]);

  const handleGoBack = (): void => {
    setIsBenefits(false);
  };

  const handleToBenefits = (): void => {
    setIsBenefits(true);
  };

  const handleApplyCoupon = (coupon: Coupon): void => {
    setCoupones(selectedPlan === PLAN_CODE_MONTHLY ? [coupon, coupones[1]] : [coupones[0], coupon]);
  };

  const handleRemoveCoupon = (): void => {
    setCoupones(selectedPlan === PLAN_CODE_MONTHLY ? [null, coupones[1]] : [coupones[0], null]);
  };

  const handleMonthlyClick = (): void => {
    if (selectedPlan !== PLAN_CODE_MONTHLY) {
      setSelectedPlan(PLAN_CODE_MONTHLY);
    }
  };

  const handleYearlyClick = (): void => {
    if (selectedPlan !== PLAN_CODE_YEARLY) {
      setSelectedPlan(PLAN_CODE_YEARLY);
    }
  };

  const handleDecreaseSeat = (): void => {
    if (seatsCount > 1) {
      setSeatsCount(seatsCount - 1);
    }
  };

  const handleIncreaseSeat = (): void => {
    setSeatsCount(seatsCount + 1);
  };

  const handlePayClick = (): void => {
    setLoading(true);

    onUpdateSubscription(selectedPlan, seatsCount, selectedApplyedCoupon?.code ?? '')
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) return <Spinner small />;

  return (
    <div className={styles.form}>
      {isMobile && isBenefits && (
        <div className={styles.benefits}>
          <Button variant='text' onClick={handleGoBack}>
            Go back
          </Button>

          <Typography variant='h2' component='h2'>
            Benefits of HIPAA<span className={styles.link}>LINK</span>
          </Typography>

          <ul className={styles.dataText}>
            {HIPAA_BENEFITS.map((dataTextItem, index) => (
              <li key={index}>
                <span className={styles.dataCheckIcon}>
                  <CheckIcon />
                </span>

                <Typography variant={isMobile ? 'body2' : 'body5'} component='span'>
                  {dataTextItem}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
      )}

      {(!isMobile || !isBenefits) && (
        <>
          {isMobile && (
            <Typography variant='body2' component='span' onClick={handleToBenefits}>
              Click here to view Subscription Benefits
            </Typography>
          )}

          <div className={styles.switcher}>
            <div
              onClick={handleMonthlyClick}
              className={classnames(styles.switcherItem, {
                [styles.switcherItemActive]: selectedPlan === PLAN_CODE_MONTHLY,
              })}
            >
              <Typography variant='body5' component='span'>
                Monthly
              </Typography>
            </div>
          </div>

          <div className={classnames(styles.row, styles.pricePerMonth)}>
            <Typography variant='h2' component='h2'>
              ${pricePerOneSeatPerMonth}/Month/Seat
            </Typography>

            <Info
              text='If your practice includes multiple providers, you can purchase and assign a seat to each practice provider. Use the staff page to assign seats and manage your staff.'
              tooltipClassName={styles.tooltip}
            />

            <Typography variant={isDesktop ? 'body2' : 'body5'} component='span' color='#626363'>
              {selectedPlan === PLAN_CODE_MONTHLY ? 'Billed Monthly' : 'Billed Annualy'}
            </Typography>
          </div>

          <div className={styles.row}>
            <Typography variant='body2' component='span'>
              Total Seats
            </Typography>

            <SeatsCountSelector count={seatsCount} onDecrease={handleDecreaseSeat} onIncrease={handleIncreaseSeat} />
          </div>

          <div className={styles.row}>
            <Typography variant='body2' component='span'>
              Discount Code
            </Typography>

            {selectedPlan === PLAN_CODE_MONTHLY && (
              <DiscountInput
                onApply={handleApplyCoupon}
                onRemove={handleRemoveCoupon}
                plan={selectedPlan}
                defaultCoupon={coupones[0]}
              />
            )}

            {selectedPlan === PLAN_CODE_YEARLY && (
              <DiscountInput
                onApply={handleApplyCoupon}
                onRemove={handleRemoveCoupon}
                plan={selectedPlan}
                defaultCoupon={coupones[1]}
              />
            )}
          </div>

          <div className={styles.row}>
            <div className={styles.calculationsRow}>
              <Typography variant={isDesktop ? 'body2' : 'body5'} component='span' color='#393534'>
                Subtotal
              </Typography>

              <Typography variant={isDesktop ? 'body2' : 'body5'} component='span' color='#393534'>
                {subtotalString}
              </Typography>
            </div>

            <div className={styles.calculationsRow}>
              <Typography variant={isDesktop ? 'body2' : 'body5'} component='span' color='#393534'>
                Discount
              </Typography>

              <Typography variant={isDesktop ? 'body2' : 'body5'} component='span' color='#393534'>
                -${subscriptionProration?.initialDiscount.toFixed(2)}
              </Typography>
            </div>

            <div className={styles.calculationsRow}>
              <Typography variant='body4' component='span'>
                Total
              </Typography>

              <Typography variant='body4' component='span'>
                ${subscriptionProration?.initialChargeAmount.toFixed(2)}
              </Typography>
            </div>
          </div>

          <div className={styles.buttons}>
            <Button variant='contained' onClick={handlePayClick}>
              Pay ${subscriptionProration?.initialChargeAmount.toFixed(2)}
            </Button>
          </div>

          {user?.clinicUser.subscriptionInfo && (
            <div className={styles.toFreeTrial}>
              <Typography variant='body3' component='span' onClick={onLeaveOnTrial}>
                Just take me to my free trial
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
}
